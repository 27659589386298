<template>
  <DetailItemEditCard :item-name="isClientIdPresent ? 'klanten' : 'projecten'" :promises.sync="promises"
    title="Nieuwe project aanmaken"
    :detailViewRouteLocation="isClientIdPresent ? { name: RouteNames.CLIENT_OVERVIEW } : { name: RouteNames.PROJECT_OVERVIEW }"
    confirmationMessage="Project werdt succesvol aangemaakt" update-store-action="projectsModule/createProject"
    :update-store-action-payload="newProject" :success-callback="projectCreatedSuccessCallback">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete :items="clients" type="text" v-model="newProject.clientId" label="Klant" required :rules="clientRules"
            item-text="clientName" item-value="id" tabindex="1" :disabled="isClientIdPresent">
            <template v-slot:item="slotProps">
              <v-list-item-content class="notranslate" v-text="slotProps.item.clientName"></v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="newProject.projectName" persistent-placeholder label="Project naam" type="text"
            :rules="projectNameRules" required />
        </v-col>
        <v-col cols="12" md="6">
          <DatePicker v-model="newProject.startDate" label="Start datum" required />
        </v-col>
        <v-col cols="12" md="6">
          <DatePicker v-model="newProject.endDate" label="Eind datum"
            :min="newProject.startDate ? new Date(newProject.startDate) : null" :default-now="false" />
        </v-col>
      </v-row>
    </template>
  </DetailItemEditCard>
</template>

<script>
import DetailItemEditCard from "@/components/shared/DetailItemEditCard.vue";
import DatePicker from "@/components/shared/DatePicker.vue";
import RouteNames from "@/router/RouteNames";
export default {
  name: "ProjectCreate",
  components: {
    DetailItemEditCard,
    DatePicker,
  },
  props: {
    clientId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      RouteNames,
      promises: [this.fetchClients()],
      clients: [],
      newProject: {
        clientId: this.clientId,
        active: true,
      },
      projectNameRules: [
        v => !!v || 'Project naam moet ingevuld worden',
      ],
      clientRules: [
        v => !!v || 'Er moet een klant geselecteerd worden',
      ],
    }
  },
  methods: {
    fetchClients() {
      return this.$store.dispatch("clientsModule/fetchNotArchivedClients")
        .then(result => {
          this.clients = result
        })
    },
    projectCreatedSuccessCallback(createdProjectDTO) {
      this.$router.push({ name: RouteNames.PROJECT_DETAIL, params: { clientId: createdProjectDTO.clientId, projectId: createdProjectDTO.id } })
    },
  },
  computed: {
    isClientIdPresent() {
      return this.clientId != null
    }
  }
}
</script>